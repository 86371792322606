import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { streamNps, streamTimezone } from "../../firebase";
import { ZONE_CLOCKS } from "../../utils/Constants";
import LatamLogo from "../../vector/latam-logo.svg";
import "./LatamHeader.css";
import { format } from 'date-fns';

function LatamHeader() {
  const [state, setState] = React.useState({
    timezoneList: [],
    lastUpdate: moment.utc().toISOString(),
  });

  const [npsData, setNpsData] = useState({
    npsLastUpdate: "...loading",
    npsL7D: "...loading",
    npsFy: "...loading",
  });

  const [isLoading, setIsLoading] = useState(true);

  const updateTime = useCallback((timezoneList) => {
    setState((prevState) => ({
      ...prevState,
      timezoneList: timezoneList,
      lastUpdate: moment.utc().toISOString(),
    }));
  }, []);

  const getTime = useCallback(
    (base) => {
      const utcDate = moment().utc();
      const timezone = state.timezoneList.find(
        (tz) =>
          tz.base === base &&
          utcDate.isSameOrAfter(
            moment.utc(tz.startDatetimeUTC, "YYYY-MM-DD HH:mm:ss", true)
          ) &&
          utcDate.isSameOrBefore(
            moment
              .utc(tz.endDatetimeUTC, "YYYY-MM-DD HH:mm:ss", true)
              .add(1, "minutes")
          )
      );
      const diffMinutes = timezone ? timezone.diffMinutes : 0;
      const date = utcDate.add(diffMinutes, "minutes");
      const minutes =
        date.get("minutes") < 10
          ? "0" + date.get("minutes")
          : date.get("minutes");
      return date.get("hours") + ":" + minutes;
    },
    [state.timezoneList]
  );

  useEffect(() => {
    let intervalId;
    let unsubscribe;

    const subscribeToTimezone = () => {
      unsubscribe = streamTimezone({
        next: (querySnapshot) => {
          const timezoneList = querySnapshot.data().data || [];
          updateTime(timezoneList);
        },
        error: (e) => {
          console.error(e);
        },
      });
    };

    const subscribeToNps = () => {
      const unsubscribe = streamNps({
        next: (data) => {
          if (
            data &&
            typeof data === "object" &&
            "npsL7D" in data &&
            "npsFy" in data &&
            "lastUpdate" in data
          ) {
            const lastUpdate = data.lastUpdate
            const npsL7DValue = parseFloat(data.npsL7D);
            const npsFyValue = parseFloat(data.npsFy);
            let formattedNpsDataDate = "--/--/----";
            if (lastUpdate) {
              const date = new Date(lastUpdate);
              if (!isNaN(date.getTime())) {
                formattedNpsDataDate = format(date, 'dd/MM/yyyy');
              } else {
                console.warn("Invalid date format for lastUpdate:", lastUpdate);
              }
            }

            if (!isNaN(npsL7DValue) && !isNaN(npsFyValue)) {
              setNpsData({
                npsLastUpdate: formattedNpsDataDate,
                npsL7D: npsL7DValue,
                npsFy: npsFyValue,
              });
            } else {
              console.error("Invalid data received from streamNps:", data);
              setNpsData({
                npsLastUpdate: "-",
                npsL7D: "-",
                npsFy: "-",
              });
            }
          } else {
            console.error("Invalid data received from streamNps:", data);
            setNpsData({
              npsLastUpdate: "-",
              npsL7D: "-",
              npsFy: "-",
            });
          }
          setIsLoading(false);
        },
        error: (error) => {
          console.error("Error fetching data:", error);
          setNpsData({
            npsLastUpdate: "-",
            npsL7D: "-",
            npsFy: "-",
          });
          setIsLoading(false);
        },
      });

      return unsubscribe;
    };

    const startInterval = () => {
      intervalId = setInterval(() => updateTime(state.timezoneList), 1000);
    };

    const cleanUpInterval = () => {
      clearInterval(intervalId);
      unsubscribe();
    };

    subscribeToTimezone();
    const unsubscribeNps = subscribeToNps();
    startInterval();

    return () => {
      cleanUpInterval();
      unsubscribeNps();
    };
  }, [updateTime, state.timezoneList]);

  return (
    <div className="latam-title">
      <img className="img-left" src={LatamLogo} alt="error"></img>
      {state.timezoneList.length > 0 &&
        ZONE_CLOCKS.map((value, index) => (
          <div className="my-clock" key={index}>
            <p className="my-clock-title">&nbsp;{value}</p>
            <div className="my-clock-bkgd">
              <p className="my-clock-style">{getTime(value)}</p>
            </div>
          </div>
        ))}

      <div className="nps-contingency">
        <div className="nps-left">
          <h4>NPS CONTINGENCY</h4>
          <p>LAST UPDATE: {npsData.npsLastUpdate}</p>
        </div>
        <div className="nps-right">
          <div className={npsData.npsL7D >= -8 ? "nps-green" : "nps-rosa"}>
            <h4>LAST 7 DAYS</h4>
            <p>{isLoading ? "Loading" : npsData.npsL7D}</p>
          </div>
          <div className={npsData.npsFy >= -8 ? "nps-green" : "nps-rosa"}>
            <h4>ANNUAL</h4>
            <p>{isLoading ? "Loading" : npsData.npsFy}</p>
          </div>
          <div className="nps-green">
            <h4>GOAL</h4>
            <p>-8%</p>
          </div>
        </div>
      </div>

      <img className="img-right" src={LatamLogo} alt="error"></img>
    </div>
  );
}

export default LatamHeader;
