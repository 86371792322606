import React from "react";
import "./CrewStandbyGroupBR.css";

function CrewStandbyGroupBR({ ctalFlightGrp }) {

  const allTimeRanges = [
    "00:00-03:00",
    "03:00-06:00",
    "06:00-09:00",
    "09:00-12:00",
    "12:00-15:00",
    "15:00-18:00",
    "18:00-21:00",
    "21:00-00:00",
  ];
  
  const rows = ctalFlightGrp.map(group => 
    group.reduce((acc, item) => {
      const key = `${item.subFleetCode}${item.baseCode}`;
      if (!acc[key]) {
        acc[key] = { key, ranges: {} };
      }
      acc[key].ranges[item.timeRange] = {
        MANDO: item.MANDO,
        CABINA: item.CABINA,
      };
      return acc;
    }, {})
  );

  const getValueOrZeroBrasil = (value) => {
    if (typeof value === "string" && value.includes("/")) {
      const [effective, remaining] = value.split("/");
      // Mantenemos los valores originales, reemplazando '-' con '0'
      return `${effective === "-" ? "0" : effective}/${remaining === "-" ? "0" : remaining
        }`;
    } else if (typeof value === "number") {
      return value === 0 ? "0/0" : `${value}/0`;
    } else if (value === undefined || value === null || value === "") {
      return "-/-";
    } else {
      return `${value}/0`;
    }
  };

  // En el renderizado:
  return (
    <div className="mySlides3 cf-fade crew">
      {rows.map((row) => {
        return (
          <React.Fragment key={row.id}> {/* Ensure you have a unique key for each row */}
            {Object.values(row).map((ct, idx) => {
              return (
                <div
                  className="summary-limops-tittle summary-limops-row-style"
                  key={idx}
                >
                  <div className="summary-limops-colx2 isBrasil">
                    {ct.key}
                  </div>
                  {allTimeRanges.map((range, rangeIdx) => {
                    const rangeData = ct.ranges[range] || {};
                    const mando = getValueOrZeroBrasil(rangeData.MANDO);
                    console.log(mando)
                    console.log(mando.length)
                    const cabina = getValueOrZeroBrasil(rangeData.CABINA);
                    return (
                      <React.Fragment key={rangeIdx}>
                        <div
                          className={`summary-limops-col ${mando === "-/-"
                              ? (mando.length < 4) ? "black" : "black-small"
                              : mando === "0/0"
                                ? (mando.length < 4) ? "red" : "red-small"
                                : (mando.length < 4) ? "white" : "white-small"
                            }`}
                        >
                          {mando}
                        </div>
                        <div
                          className={`summary-limops-col ${cabina === "-/-"
                              ? (cabina.length < 4) ? "black" : "black-small"
                              : cabina === "0/0"
                                ? (cabina.length < 4) ? "red" : "red-small"
                                : (cabina.length < 4) ? "white" : "white-small"
                            }`}
                        >
                          {cabina}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );

}
export default CrewStandbyGroupBR;
